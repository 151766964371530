<template>
  <div class="myVehicle">
    <div class="nav">
      <div class="nav_title">设置</div>
      <div class="nav_left_btn" @click="toPage('Center')">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <!-- functionalServices -->
    <ul class="list">
      <li @click="toPage('FaceBinding')">
        <p>人脸绑定</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>
      <li v-if="!isWeixin" @click="toPage('AccUnbundling')">
        <p>账号解绑</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>
      <li
        v-if="JSON.parse(user.organization.functionalServices).includes(1)"
        @click="toPage('PwdSetting')"
      >
        <p>消费密码设置</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>
      <!-- <li @click="unbindFn">
        <p>一键解除绑定</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>-->
      <li
        v-if="user.classManager && user.classManager.id"
        @click="toPage('PickInform')"
      >
        <p>接送信息设置</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>
      <li v-if="familySetFlag" @click="toPage('FamilyList')" >
        <p>家属账户</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>
    </ul>
    <ul class="bottom">
      <!-- <li @click="toPage('MyVehicle')">
        <p>我的车辆</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>-->
      <!-- <li
        @click="toPage('WithoutCode')"
        v-if="JSON.parse(user.organization.paymentSetting).eWallet && JSON.parse(user.organization.paymentSetting).eWallet.appId !==''"
      >
        <p>免密支付</p>
        <p>
          <img :src="right" alt />
        </p>
      </li>-->
    </ul>
  </div>
</template>

<script>
import cancel from '@/assets/cancel.png';
import right from '@/assets/right.png';
// import { unbindAPI } from '@/api/api'
// import { Toast } from 'vant'
import { getUserSystemSetupAPI } from '@/api/family.js'
export default {
  data() {
    return {
      cancel,
      right,
      infoData: {},
      user: {},
      isWeixin: sessionStorage.getItem('weixin'),
      familySetFlag: false
    };
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem('personInfo'));
    console.log(this.user);
    this.getUserSystemSetupFn()
  },
  methods: {
    toBack() {
      this.$router.back();
    },
    toPage(name) {
      this.$router.push({ name });
    },

    async getUserSystemSetupFn() {
      const organizationId = JSON.parse(sessionStorage.getItem("personInfo")).organizationId;
      const user = JSON.parse(sessionStorage.getItem("personInfo"));
      const res = await getUserSystemSetupAPI(organizationId);
      if (res.code === 0 && res.data !== null) {
        if (JSON.parse(res.data.setting) !== "{}") {
          if (JSON.parse(res.data.setting).family_set.flag && user.role === 0) {
            this.familySetFlag = true
          } else {
            this.familySetFlag = false
          }
        }
      }
    },
    // unbindFn() {
    //   const custID = sessionStorage.getItem("custID");
    //   unbindAPI(custID)
    //     .then((res) => {
    //       if (res.code === 0) {
    //         Toast("解绑成功!");
    //         this.$router.push({ name: "Login" });
    //       }
    //     })
    //     .catch(() => {
    //       Toast("解除绑定失败!");
    //     });
    // },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .van-field__body {
  display: block;
  box-sizing: border-box;
  padding: 0 5px;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid rgba(181, 201, 210, 0.6);
}
.myVehicle {
  height: 100%;
  display: flex;
  flex-direction: column;
  //   background: #fff;
  background: rgba(242, 242, 242);
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .list {
    background: #ffffff;
    width: 98%;
    margin: 0 auto;
    margin-top: 3.5rem;
    color: rgba(152, 152, 152);
    li {
      width: 98%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      height: 3rem;
      align-items: center;
      p {
        margin: 0;
        img {
          width: 1.5rem;
        }
      }
    }
    li {
      border-bottom: 1px solid #eee;
    }
    li:last-child {
      border: none;
    }
  }
  .bottom {
    margin-top: 0.75rem;
    width: 100%;
    color: rgba(152, 152, 152);
    li {
      display: flex;
      justify-content: space-between;
      height: 3rem;
      align-items: center;
      margin-bottom: 0.75rem;
      background: #ffffff;
      p {
        margin: 0;
        text-indent: 0.75rem;
        margin-right: 0.7rem;
        img {
          width: 1.5rem;
        }
      }
    }
  }
}
</style>
